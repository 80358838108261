import './App.css';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Manila');

function App() {

  const thisYear = moment().tz('Asia/Manila').year();

  const birthdays = {
    kit: {
      birthdate: '1990-01-17',
      birthday: '01-17',
      age: moment().diff(moment('1990-01-17'),'year')
    },
    rox: {
      birthdate: '1990-10-07',
      birthday: '10-07',
      age: moment().diff(moment('1990-10-07'),'year')
    },
    rianne: {
      birthdate: '2012-04-07',
      birthday: '04-07',
      age: moment().diff(moment('2012-04-07'),'year')
    },
    annibel: {
      birthdate: '2013-07-26',
      birthday: '07-26',
      age: moment().diff(moment('2013-07-26'),'year')
    },
    dc: {
      birthdate: '2015-07-08',
      birthday: '07-08',
      age: moment().diff(moment('2015-07-08'),'year')
    },
    lolo: {
      birthdate: '1966-10-19',
      birthday: '10-19',
      age: moment().diff(moment('1966-10-19'),'year')
    },
    lola: {
      birthdate: '1966-11-30',
      birthday: '11-30',
      age: moment().diff(moment('1966-11-30'),'year')
    },

    derik: {
      birthdate: '1986-07-16',
      birthday: '07-16',
      age: moment().diff(moment('1986-07-16'),'year')
    },

    tisoy: {
      birthdate: '1994-04-23',
      birthday: '04-23',
      age: moment().diff(moment('1994-04-23'),'year')
    },

    al: {
      birthdate: '1998-09-17',
      birthday: '09-17',
      age: moment().diff(moment('1998-09-17'),'year')
    },
    kat: {
      birthdate: '1987-09-26',
      birthday: '09-26',
      age: moment().diff(moment('1987-09-26'),'year')
    },
    micah: {
      birthdate: '1999-07-17',
      birthday: '07-17',
      age: moment().diff(moment('1999-07-17'),'year')
    },
    zo: {
      birthdate: '2014-11-06',
      birthday: '11-06',
      age: moment().diff(moment('2014-11-06'),'year')
    },
    kaielle: {
      birthdate: '2009-01-14',
      birthday: '01-14',
      age: moment().diff(moment('2009-01-14'),'year')
    },
    kalel: {
      birthdate: '2017-11-24',
      birthday: '11-24',
      age: moment().diff(moment('2017-11-24'),'year')
    }
  }

  const nextBirthday = (person) => {

    person = person.toLowerCase();

    const next = moment(thisYear+'-'+birthdays[person].birthday).tz('Asia/Manila');

    if (moment().tz('Asia/Manila').isAfter(next)) {
      return next.add(1, 'year').format('YYYY-MM-DD');
    }

    return next.tz('Asia/Manila').format('YYYY-MM-DD');
  }

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }

  const BirthdayBox = (props) => {

    const person = props.person.toLowerCase();

    return <div className='col-12 col-sm-4 birthday-box'>
      {
        moment().tz('Asia/Manila').format('YYYY-MM-DD') !== moment(thisYear+'-'+birthdays[person].birthday).tz('Asia/Manila').format('YYYY-MM-DD') ?
          <>
          <Countdown date={nextBirthday(person)}
            renderer={props => <h3>{props.days} Days</h3>}
          />
          <h6>{props.person}'s {ordinal_suffix_of(birthdays[person].age + 1)} birthday</h6>
          </>
        : <h3>Happy {ordinal_suffix_of(birthdays[person].age)} Birthday {props.person}!</h3>
      }
    </div>
  }

  return (
    <div className='container p-5'>
      <div className="row g-1">
        
        <BirthdayBox person="Kit" />

        <BirthdayBox person="Rox" />
        <BirthdayBox person="Rianne" />
        <BirthdayBox person="Annibel" />

        <BirthdayBox person="DC" />

        <BirthdayBox person="Lolo" />
        <BirthdayBox person="Lola" />

        <BirthdayBox person="Derik" />
        <BirthdayBox person="Tisoy" />
        <BirthdayBox person="Al" />

        <BirthdayBox person="Kat" />

        <BirthdayBox person="Micah" />

        <BirthdayBox person="Zo" />
        <BirthdayBox person="Kaielle" />
        <BirthdayBox person="Kalel" />


      </div>
    </div>
  );
}

export default App;
